import React from 'react';
import SelectOptionTable from './select-option-table';
import { fetchContactsByContactKey } from '../../../actions/contacts';

class SelectDevicesContent extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			items: [],
			init: true
		};
		this.i18n = this.props.i18n;
		this.columns = [
			{
				columnName: this.i18n.get('test_send_select_devices_column_device_id'),
				columnProperty: 'deviceId',
				sortable: true,
				width: '7rem',
				primary: true
			},
			{
				columnName: this.i18n.get('test_send_select_devices_column_system_token'),
				columnProperty: 'systemToken',
				sortable: true,
				width: '7rem'
			},
			{
				columnName: this.i18n.get('test_send_select_devices_column_platform'),
				columnProperty: 'platform',
				sortable: true,
				width: '4rem'
			},
			{
				columnName: this.i18n.get('test_send_select_devices_column_created'),
				columnProperty: 'createdDate',
				sortable: true,
				width: '7rem'
			},
			{
				columnName: this.i18n.get('test_send_select_devices_column_modified'),
				columnProperty: 'modifiedDate',
				sortable: true,
				width: '7rem'
			}
		];
		this.changeChild = React.createRef();
		this.MAX_SELECT_CONTACTS = 50;
		this.contactKey = this.props.contactKey;
		this.selectedOption = this.props.selectedOption;
		this.fetchContacts();
	}

	fetchContacts = () => {
		fetchContactsByContactKey(this.contactKey).then((results) => {
			if ('err' in results) {
				this.setState({ items: results.items, init: false, err: results.err });
			}	else {
				this.changeChild.current.state.items = results.items;
				this.setState({ items: results.items, init: false, err: null });
			}
		});
	};

	render () {
		return (
			<div className={this.state.items.length > this.MAX_SELECT_CONTACTS ? 'itemsOverMaxSelection' : ''}>
				<SelectOptionTable
					columnHeaders={this.columns}
					items={this.state.items}
					id="select-contact-devices"
					ref={this.changeChild}
					loader={this.state.init}
					checkbox
					limitSelect={this.MAX_SELECT_CONTACTS}
					callbackValue={this.selectedOption}
					err={this.state.err}
					i18n={this.i18n}
				/>
			</div>
		);
	}
}

export default SelectDevicesContent;
