/* eslint-disable import/prefer-default-export */

import moment from 'moment';
import checkResponseStatus from '../utilities/check-response-status';
import fetchOptions from '../constants/fetch-options';
import { hasProperty } from '../utilities/helper';
import PostmongerStore from '../utilities/postmonger';
import I18n from '../utilities/i18n';

const i18n = I18n(PostmongerStore.cultureCode);
const BASE_URL = '/fuelapi/';
const MAX_CONTACTS_DE = 50;

moment.locale(i18n.getMomentLocale());

const fetchCategories = (id) => {
	let url = `${BASE_URL}`;

	// add specific endpoint
	url += `platform-internal/v1/categories/${id}/children`;

	// all Categories need filters
	url += '?$page=1&$pageSize=1000&$orderBy=Name%20ASC&&$filter=categorytype%20eq%20dataextension%20or%20categorytype%20eq%20shared_data%20or%20categorytype%20eq%20shared_dataextension';

	return fetch(url, fetchOptions).then(checkResponseStatus).then((data) => {
		if (!data || !hasProperty(data, 'items')) {
			return {};
		}
		return data;
	}).then((data) => {
		if (!data) {
			return {};
		}

		const categories = [];
		data.items.forEach((category) => {
			categories.push({
				id: `${category.id}`,
				key: category.key,
				label: category.name,
				hasChildren: category.hasChildren
			});
		});

		return categories;
	});
};

const fetchCustomObjects = (id) => {
	let url = `${BASE_URL}`;

	// Add specific endpoint
	url += `internal/v1/customobjects/category/${id}`;

	// Add query Params
	url += '?$page=1&$pageSize=1000&retrievalType=6&$orderBy=Name%20ASC';

	return fetch(url, fetchOptions).then(checkResponseStatus).then((data) => {
		if (!data || !hasProperty(data, 'items')) {
			return {};
		}
		return data;
	}).then((data) => {
		if (!data) {
			return {};
		}

		const customObjects = [];
		data.items.forEach((obj) => {
			if (obj.totalRowCount === 0 || obj.totalRowCount > MAX_CONTACTS_DE) {
				return;
			}
			customObjects.push({
				id: obj.id,
				population: obj.totalRowCount,
				name: obj.name,
				modifiedDate: moment(obj.modifiedDate).format('lll'),
				createdByName: obj.createdByName
			});
		});

		return customObjects;
	});
};

export { fetchCategories, fetchCustomObjects };
