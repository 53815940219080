import React from 'react';
import Datepicker from '@salesforce/design-system-react/components/date-picker';
import Timepicker from '@salesforce/design-system-react/components/time-picker';
import PropTypes from 'prop-types';
import moment from 'moment';
import Input from '@salesforce/design-system-react/components/input';

class DateTimePicker extends React.Component {
	constructor (props) {
		super(props);

		moment.locale(props.i18n.getMomentLocale());

		this.dateTimePickerRef = React.createRef();
	}

	componentDidMount () {
		// Add readonly attribute to Date Picker input components
		const datePickerInput = this.dateTimePickerRef.current.querySelector('.slds-dropdown-trigger input:not([readonly])');
		if (datePickerInput) {
			datePickerInput.setAttribute('readonly', 'readonly');
		}

		// Add readonly attribute to Time Picker input components
		const timePickerInput = this.dateTimePickerRef.current.querySelector('.slds-p-left_x-small input:not([readonly])');
		if (timePickerInput) {
			timePickerInput.setAttribute('readonly', 'readonly');
		}
	}

	render () {
		const {
			i18n,
			selectedDate,
			selectedTime,
			labels,
			onSelectDate,
			onSelectTime,
			earliestDate, // Optional property for the earliest date you can select
			disabledDateRange
		} = this.props;

		const datePickerProps = {
			triggerClassName: 'slds-size_1-of-2 slds-p-right_x-small',
			labels: {
				label: labels.datePicker,
				abbreviatedWeekDays: [
					i18n.get('abbreviated_weekdays_sun'),
					i18n.get('abbreviated_weekdays_mon'),
					i18n.get('abbreviated_weekdays_tue'),
					i18n.get('abbreviated_weekdays_wed'),
					i18n.get('abbreviated_weekdays_thu'),
					i18n.get('abbreviated_weekdays_fri'),
					i18n.get('abbreviated_weekdays_sat')
				],
				months: [
					i18n.get('months_january'),
					i18n.get('months_february'),
					i18n.get('months_march'),
					i18n.get('months_april'),
					i18n.get('months_may'),
					i18n.get('months_june'),
					i18n.get('months_july'),
					i18n.get('months_august'),
					i18n.get('months_september'),
					i18n.get('months_october'),
					i18n.get('months_november'),
					i18n.get('months_december')
				],
				placeholder: i18n.get('custom_timeframe_scheduler_date_picker_placeholder'),
				today: i18n.get('today'),
				weekDays: [
					i18n.get('weekdays_sunday'),
					i18n.get('weekdays_monday'),
					i18n.get('weekdays_tuesday'),
					i18n.get('weekdays_wednesday'),
					i18n.get('weekdays_thursday'),
					i18n.get('weekdays_friday'),
					i18n.get('weekdays_saturday')
				]
			},
			required: true,
			onChange: (event, data) => {
				onSelectDate(data.formattedDate);
			},
			formatter: (date) => moment(date, 'MM/DD/YYYY').format('L'), // Format date based on locale
			parser: (str) => moment(str, 'L').toDate(), // Parse formatted date to universal date object
			dateDisabled: (date) => {
				// if the earliestDate is provided, disable dates before the provided date
				if (earliestDate) {
					return moment(date.date).isBefore(moment(earliestDate, 'L'), 'day');
				}

				if (disabledDateRange) {
					return moment(date.date).isBefore(disabledDateRange.to) || moment(date.date).isAfter(disabledDateRange.from);
				}

				// Disable the dates before today
				return moment(date.date).isBefore(moment(new Date()), 'day');
			}
		};

		if (selectedDate && selectedDate.length > 0) {
			datePickerProps.value = moment(selectedDate, 'L').toDate();
			delete datePickerProps.input;
		} else {
			datePickerProps.value = new Date();
			datePickerProps.input = <Input value={i18n.get('custom_timeframe_scheduler_date_picker_placeholder')} />; // Override input box of date picker to show placeholder when selectedDate is not available.
		}

		const timePickerProps = {
			stepInMinutes: 30,
			label: labels.timePicker,
			required: true,
			placeholder: i18n.get('custom_timeframe_scheduler_time_picker_placeholder'),
			onDateChange: (date, time) => {
				onSelectTime(time);
			},
			formatter: (date) => moment(date).format('LT')
		};

		if (selectedTime && selectedTime.length > 0) {
			timePickerProps.value = moment(selectedTime, 'LT').toDate();
			timePickerProps.strValue = selectedTime;
		} else {
			timePickerProps.value = new Date(null);
			timePickerProps.strValue = '';
		}

		return (
			<div className="date-time-picker slds-grid" ref={this.dateTimePickerRef}>
				<Datepicker {...datePickerProps} />
				<div className="slds-size_1-of-2 slds-p-left_x-small">
					<Timepicker {...timePickerProps} />
				</div>
			</div>
		);
	}
}

DateTimePicker.propTypes = {
	i18n: PropTypes.object.isRequired,
	selectedDate: PropTypes.string,
	selectedTime: PropTypes.string,
	onSelectDate: PropTypes.func.isRequired,
	onSelectTime: PropTypes.func.isRequired,
	earliestDate: PropTypes.string
};

export default DateTimePicker;
