const getInteractiveButtons = (messageDefinition) => {
	let buttonLabels;
	try {
		const { content } = messageDefinition || {};
		const { customBlockData } = content?.views?.push?.meta?.options || {};
		const { richFeatures } = customBlockData || {};
		const { buttons } = richFeatures || {};
		if (buttons?.length) {
			buttonLabels = [];
			buttons.forEach((button) => {
				const { title, icon } = button;
				const buttonLabel = title.text;
				if (buttonLabel) {
					buttonLabels.push(buttonLabel);
				} else if (icon) {
					buttonLabels.push(icon); // Show icon name if button text is missing and icon is there
				}
			});
			if (buttonLabels.length) return buttonLabels;
		}
		return buttonLabels;
	} catch (e) {
		console.log(e);
		return buttonLabels;
	}
};

export default getInteractiveButtons;
