import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// utilities
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import Campaign from '@salesforce-mc/campaign-selector-vanilla';
import { hasProperty } from '../utilities/helper';
import TimezoneUtils from '../utilities/timezone';
import PostmongerStore from '../utilities/postmonger';

import { NOTIFICATION_SOUND } from '../constants';

// components
import DeliveryOptionsComponent from '../components/delivery-options';

// actions
import { setNotificationSound, setInteractiveNotification, setIOSBadgeToggleValue, setSendWindowToggleValue, setCampaign, setSendWindowTime, setSendWindowTimezone, setCopyToInboxToggleValue, setCopyToInboxDate, setCopyToInboxTime, setCopyToInboxTimezone } from '../actions/delivery-options';
import { fetchMobileAppDetail, setInteractiveNotifications, setMobileAppDetail } from '../actions/mobile-app-selection';
import isMessageCarousel from '../utilities/check-carousel';
import getTemplateCategory from '../utilities/template-category';
import getInteractiveButtons from '../utilities/interactive-buttons';
import { getSounds } from '../utilities/asset-sounds';

class DeliveryOptions extends React.Component {
	constructor (props) {
		super(props);

		moment.locale(props.i18n.getMomentLocale());

		if (PostmongerStore.featureFlag['RichNotification.PushActivity.Enabled'] === 'true') {
			this.isAdvancedPushEnabled = true;
		}

		this.metaData = props.deliveryOptionsStore[props.sectionKey];

		if (!hasProperty(this.metaData, 'payload')) {
			this.metaData.payload = {};
		}

		if (PostmongerStore.featureFlag.AdvanceInboxUI === 'true' && this.metaData.copyToInboxTimeZone === undefined) { // TODO: Update feature flag value for advanced inbox
			// Get user's information using mclib
			props.mcLib.getUserInfo((userInfo) => {
				if (userInfo.timeZone === undefined || (hasProperty(this.metaData, 'timezoneName') && this.metaData.timezoneName.length > 0)) {
					return; // If mclib is not available, skip it
				}

				const timezoneId = Number.isInteger(userInfo.timeZone.timeZoneId) ? userInfo.timeZone.timeZoneId.toString() : userInfo.timeZone.timeZoneId; // Convert Timezone Id to String
				this.userTimezone = TimezoneUtils.getTimezoneItemForComboboxById(timezoneId);
				this.momentObjWithTimezone = moment(moment.tz(moment(), this.userTimezone.name));

				// initialize copy to inbox end date and time
				this.props.setCopyToInboxDate(this.momentObjWithTimezone.add(1, 'M').format('L'));
				this.props.setCopyToInboxTime(this.momentObjWithTimezone.format('LT'));

				// initialize copy to inbox timezone
				this.props.setCopyToInboxTimezone(null, {
					selection: [this.userTimezone]
				});
			});
		}

		props.setInteractiveNotification(null, {
			selection: this.metaData.selectedInteractiveNotification ? this.metaData.selectedInteractiveNotification : []
		});
		props.setInteractiveNotifications(this.metaData.interactiveNotifications);
		props.setMobileAppDetail(props.deliveryOptionsStore.messageConfiguration.mobileAppDetail);

		this.renderNotificationSoundWithExistingData();
		this.renderInteractiveNotificationWithExistingData();
		this.renderIOSBadgeToggleWithExistingData();
		this.renderCampaignWithExistingData();

		if (PostmongerStore.isBROn('push_jb_send_window')) {
			this.renderSendWindowWithExistingData();
		}

		if (PostmongerStore.featureFlag.AdvanceInboxUI === 'true') {
			this.renderCopyToInboxWithExistingData();
		}

		this.onAddCampaign = this.onAddCampaign.bind(this);
		this.onRemoveCampaign = this.onRemoveCampaign.bind(this);
	}

	componentDidUpdate () {
		if (isEmpty(this.props.selectedInteractiveNotification) && !isEmpty(this.props.interactiveNotifications) && hasProperty(this.metaData.payload, 'interactiveNotification')) {
			this.renderInteractiveNotificationWithExistingData();

			return;
		}

		this.saveNotificationSound();
		this.saveInteractiveNotification();
		this.saveIOSBadgeToggleValue();
		this.saveCampaign();

		if (PostmongerStore.isBROn('push_jb_send_window')) {
			this.saveSendWindow();
		}

		if (PostmongerStore.featureFlag.AdvanceInboxUI === 'true') { // TODO: Update feature flag value for advanced inbox
			this.saveCopyToInboxDate();
		}

		// print out payload for debugging purpose
		console.log(this.props.deliveryOptionsStore);
	}

	renderNotificationSoundWithExistingData () {
		if (!hasProperty(this.metaData.payload, 'sound')) {
			return;
		}

		this.props.setNotificationSound({
			target: {
				value: this.metaData.payload.sound === '' ? NOTIFICATION_SOUND.NONE : this.metaData.payload.sound
			}
		});
	}

	renderInteractiveNotificationWithExistingData () {
		// On edit flow, if application data is alerady available but detail prop is empty, fetch the app detail manually
		if (isEmpty(this.props.mobileAppDetail) && hasProperty(this.props.deliveryOptionsStore.messageConfiguration, 'application')) {
			this.props.fetchMobileAppDetail(this.props.deliveryOptionsStore.messageConfiguration.application.id);

			return;
		}

		// When selected app's detail is available but it doesn't have interactive notifications data, remove interactive notification from payload
		if (!isEmpty(this.props.mobileAppDetail) && isEmpty(this.props.interactiveNotifications)) {
			// Clear dropdown selection prop
			this.props.setInteractiveNotification(null, {
				selection: []
			});

			delete this.metaData.payload.interactiveNotification;

			return;
		}

		if (!isEmpty(this.props.mobileAppDetail) && !isEmpty(this.props.interactiveNotifications) && hasProperty(this.metaData.payload, 'interactiveNotification')) {
			this.props.setInteractiveNotification(null, {
				selection: [{
					id: this.metaData.payload.interactiveNotification.id ? this.metaData.payload.interactiveNotification.id : this.metaData.payload.interactiveNotification.name,
					label: this.metaData.payload.interactiveNotification.name,
					value: this.metaData.payload.interactiveNotification.name,
					buttons: this.metaData.payload.interactiveNotification.buttons
				}]
			});
		}
	}

	renderIOSBadgeToggleWithExistingData () {
		if (!hasProperty(this.metaData.payload, 'iosBadge')) {
			return;
		}

		this.props.setIOSBadgeToggleValue(null, {
			checked: this.metaData.payload.iosBadge
		});
	}

	renderCampaignWithExistingData () {
		if (!hasProperty(this.metaData.payload, 'campaignId')) {
			return;
		}

		this.props.setCampaign(this.metaData.selectedCampaign);
	}

	renderSendWindowWithExistingData () {
		if (!hasProperty(this.metaData.payload, 'sendWindowStartTime')) {
			return;
		}

		// Enable send window toggle
		this.props.setSendWindowToggleValue(null, {
			checked: true
		});

		// Set start time picker
		if (hasProperty(this.metaData, 'sendWindowStartTime')) {
			this.props.setSendWindowTime('start', this.metaData.sendWindowStartTime);
		}

		// Set end time picker
		if (hasProperty(this.metaData, 'sendWindowEndTime')) {
			this.props.setSendWindowTime('end', this.metaData.sendWindowEndTime);
		}

		// Set timezone picker
		if (hasProperty(this.metaData, 'sendWindowTimeZone')) {
			this.props.setSendWindowTimezone(null, {
				selection: [TimezoneUtils.getTimezoneItemForComboboxById(this.metaData.sendWindowTimeZone.id)]
			});
		}
	}

	renderCopyToInboxWithExistingData () {
		if (isMessageCarousel(this.props.deliveryOptionsStore.messageDefinition)) {
			// Reset all props with initial state
			this.props.setCopyToInboxToggleValue(null, {
				checked: false
			});
			this.props.setCopyToInboxDate('');
			this.props.setCopyToInboxTime('');
			this.props.setCopyToInboxTimezone(null, {
				selection: []
			});

			return;
		}

		if (!this.metaData.payload.isPushCopyToInbox) {
			return;
		}

		// Enable toggle
		this.props.setCopyToInboxToggleValue(null, {
			checked: true
		});

		// Set end date
		if (hasProperty(this.metaData, 'copyToInboxEndDate')) {
			this.props.setCopyToInboxDate(this.metaData.copyToInboxEndDate);
		}

		// Set end time
		if (hasProperty(this.metaData, 'copyToInboxEndTime')) {
			this.props.setCopyToInboxTime(this.metaData.copyToInboxEndTime);
		}

		// Set timezone
		if (hasProperty(this.metaData, 'copyToInboxTimeZone')) {
			this.props.setCopyToInboxTimezone(null, {
				selection: [TimezoneUtils.getTimezoneItemForComboboxById(this.metaData.copyToInboxTimeZone.id)]
			});
		}
	}

	saveNotificationSound () {
		const notificationSoundTranslationMap = {
			default: this.props.i18n.get('notification_sound_options_default'),
			'custom.caf': this.props.i18n.get('custom'),
			none: this.props.i18n.get('none')
		};
		this.metaData.selectedNotificationSound = notificationSoundTranslationMap[this.props.selectedNotificationSound];
		this.metaData.payload.sound = this.props.selectedNotificationSound === NOTIFICATION_SOUND.NONE ? '' : this.props.selectedNotificationSound;
	}

	saveInteractiveNotification () {
		if (!hasProperty(this.props.mobileAppDetail, 'categories')) {
			// If application data is available but the app hasn't been fetched, stop proceeding.
			if (isEmpty(this.props.mobileAppDetail) && hasProperty(this.props.deliveryOptionsStore.messageConfiguration, 'application')) {
				return;
			}

			delete this.metaData.selectedInteractiveNotification;
			delete this.metaData.payload.interactiveNotification;

			return;
		}

		if (isEmpty(this.props.selectedInteractiveNotification)) {
			delete this.metaData.selectedInteractiveNotification;
			delete this.metaData.payload.interactiveNotification;

			return;
		}

		this.metaData.selectedInteractiveNotification = this.props.selectedInteractiveNotification;
		this.metaData.payload.interactiveNotification = {
			id: this.props.selectedInteractiveNotification[0].id,
			name: this.props.selectedInteractiveNotification[0].value,
			buttons: this.props.selectedInteractiveNotification[0].buttons
		};
	}

	saveIOSBadgeToggleValue () {
		this.metaData.isIOSBadgeToggleEnabled = this.props.isIOSBadgeToggleEnabled ? this.props.i18n.get('enabled') : this.props.i18n.get('disabled');
		this.metaData.payload.iosBadge = this.props.isIOSBadgeToggleEnabled;
	}

	saveCampaign () {
		if (isEmpty(this.props.selectedCampaign)) {
			delete this.metaData.selectedCampaign;
			delete this.metaData.payload.campaignId;

			return;
		}

		this.metaData.selectedCampaign = this.props.selectedCampaign;
		this.metaData.payload.campaignId = this.props.selectedCampaign.campaignId;
	}

	saveSendWindow () {
		this.metaData.isSendWindowToggleEnabled = this.props.isSendWindowToggleEnabled;

		if (!this.props.isSendWindowToggleEnabled) {
			delete this.metaData.sendWindowStartTime;
			delete this.metaData.sendWindowEndTime;
			delete this.metaData.sendWindowTimeZone;

			delete this.metaData.payload.sendWindowStartTime;
			delete this.metaData.payload.sendWindowEndTime;
			delete this.metaData.payload.sendWindowTimeZone;

			return;
		}

		// Save metadata
		this.metaData.sendWindowStartTime = this.props.sendWindowStartTime;
		this.metaData.sendWindowEndTime = this.props.sendWindowEndTime;
		this.metaData.sendWindowTimeZone = this.props.sendWindowTimeZone;

		// Save payload
		this.metaData.payload.sendWindowStartTime = this.props.sendWindowStartTime.length > 0 ? moment(this.props.sendWindowStartTime, 'LT').format('YYYY-MM-DDTHH:mm') : '';
		this.metaData.payload.sendWindowEndTime = this.props.sendWindowEndTime.length > 0 ? moment(this.props.sendWindowEndTime, 'LT').format('YYYY-MM-DDTHH:mm') : '';
		this.metaData.payload.sendWindowTimeZone = this.props.sendWindowTimeZone.value;
	}

	saveCopyToInboxDate () {
		this.metaData.isCopyToInboxToggleEnabled = this.props.isCopyToInboxToggleEnabled;

		if (isMessageCarousel(this.props.deliveryOptionsStore.messageDefinition) || !this.props.isCopyToInboxToggleEnabled) {
			delete this.metaData.copyToInboxEndDate;
			delete this.metaData.copyToInboxEndTime;
			delete this.metaData.copyToInboxTimeZone;

			delete this.metaData.payload.isPushCopyToInbox;
			delete this.metaData.payload.endDate;
			delete this.metaData.payload.pushCopyToInboxScheduledTimezone;

			return;
		}

		// Save metadata
		this.metaData.copyToInboxEndDate = this.props.copyToInboxEndDate;
		this.metaData.copyToInboxEndTime = this.props.copyToInboxEndTime;
		this.metaData.copyToInboxTimeZone = this.props.copyToInboxTimeZone;

		// Save payload
		this.metaData.payload.isPushCopyToInbox = this.props.isCopyToInboxToggleEnabled;
		this.metaData.payload.endDate = this.getCopyToInboxEndDate();
		this.metaData.payload.pushCopyToInboxScheduledTimezone = {
			name: this.props.copyToInboxTimeZone.value,
			offset: this.props.copyToInboxTimeZone.utcOffset
		};
	}

	getCopyToInboxEndDate () {
		const endDateVal = this.props.copyToInboxEndDate;
		const endTimeVal = this.props.copyToInboxEndTime;
		const timezone = this.props.copyToInboxTimeZone;

		const endSelectedTime = `${moment(endDateVal).format('YYYY-MM-DD')} ${moment(endTimeVal, 'LT').format('HH:mm:ss.SSS')}`;

		const endUtc = moment.tz(endSelectedTime, timezone.name);

		return endUtc.toISOString();
	}

	onAddCampaign () {
		const self = this;
		let selection = this.props.selectedCampaign ? this.props.selectedCampaign : {};

		const CampaignSelectorModal = {
			footer: {
				buttons: [
					{
						id: 'campaign-select-btn',
						text: this.props.i18n.get('majik_button_select'),
						onClick: 'onSelectCampaign'
					}
				]
			},
			onInitialize: function (options) {
				// you can also access options on `this`
				this.element.innerHTML = `
					<div class="scm-common-header-text slds-text-heading_medium slds-text-color_default slds-truncate">
						${options.title}
					</div>
					<div id="campaign-selector"></div>
				`;

				const campaignSelectorConfig = {
					locale: self.props.i18n.getLocale()
				};

				if (!isEmpty(selection)) {
					campaignSelectorConfig.selection = selection;
				}

				const campaignSelector = new Campaign(this.element.querySelector('#campaign-selector'), campaignSelectorConfig);

				campaignSelector.onClickRow = (response) => {
					selection = response;
				};
			},
			onSelectCampaign: function () {
				self.props.setCampaign(selection);
				this.close();
			}
		};

		this.props.createModal(CampaignSelectorModal, {
			title: self.props.i18n.get('select_campaign')
		}).show();
	}

	onRemoveCampaign () {
		this.props.setCampaign({});
	}

	getTemplateCategoryIfCarousel () {
		let templateCategory;
		if (this.isAdvancedPushEnabled) {
			const selectedMessageDefinition = this.props.deliveryOptionsStore.messageDefinition;
			const appDetails = this.props.mobileAppDetail;
			if (!isEmpty(selectedMessageDefinition) && !isEmpty(appDetails) && isMessageCarousel(selectedMessageDefinition)) {
				templateCategory = { show: true, categoryName: getTemplateCategory(appDetails) };
			}
		}

		return templateCategory;
	}

	getAssetInteractiveButtons () {
		let assetInteractiveButtons;
		if (this.isAdvancedPushEnabled) {
			const selectedMessageDefinition = this.props.deliveryOptionsStore.messageDefinition;
			if (!isEmpty(selectedMessageDefinition)) {
				assetInteractiveButtons = getInteractiveButtons(selectedMessageDefinition);
			}
		}
		return assetInteractiveButtons;
	}

	getAssetSounds () {
		let assetSounds;
		if (this.isAdvancedPushEnabled) {
			const selectedMessageDefinition = this.props.deliveryOptionsStore.messageDefinition;
			if (!isEmpty(selectedMessageDefinition)) {
				assetSounds = getSounds(selectedMessageDefinition, this.props.i18n);
			}
		}
		return assetSounds;
	}

	checkIfAssetCarousel () {
		let isCarousel = false;
		if (this.isAdvancedPushEnabled) {
			const selectedMessageDefinition = this.props.deliveryOptionsStore.messageDefinition;
			if (!isEmpty(selectedMessageDefinition)) {
				isCarousel = isMessageCarousel(selectedMessageDefinition);
			}
		}
		return isCarousel;
	}

	render () {
		return (
			<DeliveryOptionsComponent
				i18n={this.props.i18n}
				selectedNotificationSound={this.props.selectedNotificationSound}
				setNotificationSound={this.props.setNotificationSound}
				setInteractiveNotification={this.props.setInteractiveNotification}
				interactiveNotifications={this.props.interactiveNotifications}
				selectedInteractiveNotification={this.props.selectedInteractiveNotification}
				isIOSBadgeToggleEnabled={this.props.isIOSBadgeToggleEnabled}
				setIOSBadgeToggleValue={this.props.setIOSBadgeToggleValue}
				isSendWindowToggleEnabled={this.props.isSendWindowToggleEnabled}
				setSendWindowToggleValue={this.props.setSendWindowToggleValue}
				onAddCampaign={this.onAddCampaign}
				selectedCampaign={this.props.selectedCampaign}
				onRemoveCampaign={this.onRemoveCampaign}
				onSelectSendWindow={this.props.setSendWindowTime}
				sendWindowStartTime={this.props.sendWindowStartTime}
				sendWindowEndTime={this.props.sendWindowEndTime}
				onSelectSendWindowTimezone={this.props.setSendWindowTimezone}
				sendWindowTimeZone={this.props.sendWindowTimeZone}
				menuValidationState={this.props.menuValidationState}
				isCopyToInboxToggleEnabled={this.props.isCopyToInboxToggleEnabled}
				setCopyToInboxToggleValue={this.props.setCopyToInboxToggleValue}
				onSelectInboxEndDate={this.props.setCopyToInboxDate}
				onSelectInboxEndTime={this.props.setCopyToInboxTime}
				copyToInboxEndDate={this.props.copyToInboxEndDate}
				copyToInboxEndTime={this.props.copyToInboxEndTime}
				copyToInboxTimeZone={this.props.copyToInboxTimeZone}
				onSelectInboxTimezone={this.props.setCopyToInboxTimezone}
				templateCategory={this.getTemplateCategoryIfCarousel()}
				assetInteractiveButtons={this.getAssetInteractiveButtons()}
				assetSounds={this.getAssetSounds()}
				isAssetCarousel={this.checkIfAssetCarousel()}
				isCopyToInboxEligible={PostmongerStore.featureFlag.AdvanceInboxUI === 'true' && !isMessageCarousel(this.props.deliveryOptionsStore.messageDefinition)}
			/>
		);
	}
}

DeliveryOptions.propTypes = {
	i18n: PropTypes.object.isRequired,
	deliveryOptionsStore: PropTypes.object.isRequired,
	sectionKey: PropTypes.string.isRequired,
	selectedNotificationSound: PropTypes.string.isRequired,
	setNotificationSound: PropTypes.func.isRequired,
	setInteractiveNotification: PropTypes.func.isRequired,
	interactiveNotifications: PropTypes.array.isRequired,
	selectedInteractiveNotification: PropTypes.array.isRequired,
	isIOSBadgeToggleEnabled: PropTypes.bool.isRequired,
	setIOSBadgeToggleValue: PropTypes.func.isRequired,
	isSendWindowToggleEnabled: PropTypes.bool,
	setSendWindowToggleValue: PropTypes.func,
	fetchMobileAppDetail: PropTypes.func.isRequired,
	mobileAppDetail: PropTypes.object,
	setInteractiveNotifications: PropTypes.func.isRequired,
	setMobileAppDetail: PropTypes.func.isRequired,
	createModal: PropTypes.func.isRequired,
	setCampaign: PropTypes.func.isRequired,
	selectedCampaign: PropTypes.object.isRequired,
	setSendWindowTime: PropTypes.func,
	sendWindowStartTime: PropTypes.string,
	sendWindowEndTime: PropTypes.string,
	sendWindowTimeZone: PropTypes.object,
	setSendWindowTimezone: PropTypes.func,
	menuValidationState: PropTypes.string
};

const mapStateToProps = (state) => ({
	interactiveNotifications: state.MobileAppSelection.interactiveNotifications,
	selectedNotificationSound: state.DeliveryOptions.selectedNotificationSound,
	selectedInteractiveNotification: state.DeliveryOptions.selectedInteractiveNotification,
	isIOSBadgeToggleEnabled: state.DeliveryOptions.isIOSBadgeToggleEnabled,
	isSendWindowToggleEnabled: state.DeliveryOptions.isSendWindowToggleEnabled,
	mobileAppDetail: state.MobileAppSelection.mobileAppDetail,
	selectedCampaign: state.DeliveryOptions.selectedCampaign,
	sendWindowStartTime: state.DeliveryOptions.sendWindowStartTime,
	sendWindowEndTime: state.DeliveryOptions.sendWindowEndTime,
	sendWindowTimeZone: state.DeliveryOptions.sendWindowTimeZone,
	isCopyToInboxToggleEnabled: state.DeliveryOptions.isCopyToInboxToggleEnabled,
	copyToInboxEndDate: state.DeliveryOptions.copyToInboxEndDate,
	copyToInboxEndTime: state.DeliveryOptions.copyToInboxEndTime,
	copyToInboxTimeZone: state.DeliveryOptions.copyToInboxTimeZone
});

const mapDispatchToProps = (dispatch) => ({
	setNotificationSound: (event) => {
		dispatch(setNotificationSound(event.target.value));
	},
	setInteractiveNotification: (event, data) => {
		dispatch(setInteractiveNotification(data.selection));
	},
	setIOSBadgeToggleValue: (event, data) => {
		dispatch(setIOSBadgeToggleValue(data.checked));
	},
	setSendWindowToggleValue: (event, data) => {
		dispatch(setSendWindowToggleValue(data.checked));
	},
	fetchMobileAppDetail: (id) => dispatch(fetchMobileAppDetail(id)),
	setInteractiveNotifications: (notifications) => {
		dispatch(setInteractiveNotifications(notifications));
	},
	setMobileAppDetail: (detail) => {
		dispatch(setMobileAppDetail(detail));
	},
	setCampaign: (campaign) => {
		dispatch(setCampaign(campaign));
	},
	setSendWindowTime: (type, time) => {
		dispatch(setSendWindowTime({
			type, time
		}));
	},
	setSendWindowTimezone: (event, data) => {
		if (isEmpty(data.selection)) {
			return; // Disable combobox selection toggle
		}

		dispatch(setSendWindowTimezone(data.selection));
	},
	setCopyToInboxToggleValue: (event, data) => {
		dispatch(setCopyToInboxToggleValue(data.checked));
	},
	setCopyToInboxDate: (date) => {
		dispatch(setCopyToInboxDate(date));
	},
	setCopyToInboxTime: (time) => {
		dispatch(setCopyToInboxTime(time));
	},
	setCopyToInboxTimezone: (event, data) => {
		if (isEmpty(data.selection)) {
			return; // Disable combobox selection toggle
		}

		dispatch(setCopyToInboxTimezone(data.selection));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryOptions);
