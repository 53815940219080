import React from 'react';
import moment from 'moment';
import Checkbox from '@salesforce/design-system-react/components/checkbox';
import Tooltip from '../common/tooltip';
import DateTimePicker from './date-time-picker';
import TimezonePicker from './timezone-picker';

function CopyToInbox ({
	i18n,
	classNames,
	isToggleEnabled,
	setToggleValue,
	endDate,
	endTime,
	onSelectDate,
	onSelectTime,
	onSelectTimezone,
	timezone
}) {
	return (
		<div className="push-copy-to-inbox-container slds-size_2-of-3">
			<div className="container-heading">{ i18n.get('send_copy_to_inbox') }</div>
			<Tooltip
				i18n={i18n}
				cid="mp-message-push-copy-to-inbox"
				replaceKeyValue={{
					'{{New SDK}}': '9.x'
				}}
			/>
			<Checkbox
				id="push-copy-to-inbox-toggle-checkbox"
				variant="toggle"
				labels={{
					toggleEnabled: i18n.get('enabled'),
					toggleDisabled: i18n.get('disabled')
				}}
				checked={isToggleEnabled}
				onChange={setToggleValue}
			/>
			{ isToggleEnabled ? (
				<div className={classNames}>
					<DateTimePicker
						i18n={i18n}
						labels={{
							datePicker: i18n.get('custom_timeframe_scheduler_end_date'),
							timePicker: i18n.get('custom_timeframe_scheduler_end_time')
						}}
						selectedDate={endDate}
						selectedTime={endTime}
						onSelectDate={onSelectDate}
						onSelectTime={onSelectTime}
						disabledDateRange={{ // Only allow one year range to be selected
							to: moment(new Date()).subtract(1, 'd'),
							from: moment(new Date()).add(1, 'Y')
						}}
					/>
					<TimezonePicker
						i18n={i18n}
						selectedTimezone={timezone}
						onSelectTimezone={onSelectTimezone}
						enabled
					/>
				</div>
			) : ''}
		</div>
	);
}

export default CopyToInbox;
