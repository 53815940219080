import MobileApplication from '../components/message-configuration/mobile-application';
import TestSendsButtonHTML from '../components/message-configuration/test-sends/test-sends-button.html';
import { hasProperty } from '../utilities/helper';
import { MAJIK_SIZE } from '../constants';
import PostmongerStore from '../utilities/postmonger';
import isMessageCarousel from '../utilities/check-carousel';
import getInteractiveButtons from '../utilities/interactive-buttons';

export default function (dom, configs) {
	const { i18n, store, sectionKey, resize, onTestSendsButtonClick } = configs;

	const TestSendsEnabled = !!(PostmongerStore.featureFlag
		&& PostmongerStore.featureFlag.TestSendPreview
		&& PostmongerStore.featureFlag.TestSendPreview.toLowerCase() === 'true'); // feature flag

	const showTestSendButton = () => {
		// Test sends is currently disabled for Adv Push messages
		if (isMessageCarousel(store.messageDefinition) || !!getInteractiveButtons(store.messageDefinition)) {
			return;
		}
		const btnWrapper = dom.parentElement.querySelector('.test-sends-button');
		if (btnWrapper) {
			btnWrapper.classList.remove('hidden');
			const btn = dom.parentElement.querySelector('#test-send-btn');
			btn.addEventListener('click', () => {
				resize(MAJIK_SIZE.FULL);
				const msgPayload = store.messageDefinition.content.views.push.meta.options.customBlockData;
				msgPayload.name = store.messageDefinition.content.name;
				onTestSendsButtonClick(store[sectionKey].payload.application, msgPayload, i18n);
			});
		}
	};

	const renderMobileApplication = () => {
		const container = dom.querySelector('#mobile-application-container');

		this.mobileapplication = new MobileApplication(container, configs, showTestSendButton);
	};

	const isRequirementSatisfied = hasProperty(store[sectionKey], 'payload')
		&& hasProperty(store[sectionKey].payload, 'application')
		&& hasProperty(store[sectionKey].payload.application, 'name')
		&& store[sectionKey].payload.application.id.length > 0
		&& store[sectionKey].payload.application.name.length > 0;

	const msgValid = store.messageDefinition
		&& store.messageDefinition.content
		&& store.messageDefinition.content.name
		&& store.messageDefinition.content.views
		&& store.messageDefinition.content.views.push
		&& store.messageDefinition.content.views.push.meta
		&& store.messageDefinition.content.views.push.meta.options
		&& store.messageDefinition.content.views.push.meta.options.customBlockData;

	const renderTestSends = () => {
		const container = dom.querySelector('#test-sends-button-container');
		container.innerHTML = TestSendsButtonHTML.toString()
			.replace('{{test_send_btn_string}}', i18n.get('test_send_button'))
			.replace('{{test_send_description_string}}', i18n.get('test_send_description'));
		if (isRequirementSatisfied && msgValid) {
			showTestSendButton();
		}
	};

	const renderHeader = () => {
		const subHeading = i18n.get('message_configuration_section_subheading');

		dom.querySelector('#subheader-container').innerHTML = subHeading;
	};

	const render = () => {
		dom.innerHTML = `
			<div class="message-configuration">
				<div id="subheader-container" class="section-subheading"></div>
				<div id="mobile-application-container"></div>
				<div id="test-sends-button-container"></div>
			</div>
		`;
	};

	const initialize = () => {
		if (!hasProperty(store[sectionKey], 'payload')) {
			store[sectionKey].payload = {};
		}

		render();
		renderHeader();
		renderMobileApplication();
		if (TestSendsEnabled) {
			renderTestSends();
		}
	};

	initialize();

	this.reRenderMobileApplication = (applications) => {
		configs.isFetching = false;
		configs.applications = applications || [];

		renderMobileApplication();
	};
}
